import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RoutingService {
	public prevUrl: string = null;
	public currentUrl: string = '/home';
	private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(this.prevUrl);
	public previousUrl$: Observable<string> = this.previousUrl.asObservable();
	private activeUrl: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentUrl);
	public activeUrl$: Observable<string> = this.activeUrl.asObservable();

	setPreviousUrl(previousUrl: string) {
		console.log('currentUrl', this.currentUrl);

		this.previousUrl.next(previousUrl);
	}

	setCurrentUrl(currentUrl: string) {
		this.currentUrl = currentUrl;
		this.activeUrl.next(currentUrl);
	}
}
